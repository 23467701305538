import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import blogTemplateStyles from "./blogTemplate.module.scss"
import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <div className={blogTemplateStyles.layout}>
        <Head title={props.data.contentfulBlogPost.title} />
        <h1 className={blogTemplateStyles.title}>
          {props.data.contentfulBlogPost.title}
        </h1>
        <p className={blogTemplateStyles.date}>
          <i>{props.data.contentfulBlogPost.publishedDate}</i>
        </p>
        <hr />

        {documentToReactComponents(
          props.data.contentfulBlogPost.body.json,
          options
        )}
      </div>
    </Layout>
  )
}

export default Blog
